import React from 'react';
import logo from '../../img/logo.png';
import './welcome.css';

const Welcome = () => {
  return (
    <section className="ftco-about d-md-flex">
    <div className="col-sm-12 one-full align-items-center" id='welcomeImg'>
      <div className="heading-section">
        <h2 className="mb-4">Welcome to <span className="logo1"><img src={logo}/>Amethyst</span> Sweets House</h2>
      </div>
      <div>
        <p>ඔබට අවශ්‍ය රසකැවිලි තොග හා සිල්ලර මිලට අපගෙන් ලබගත හැක.</p>
      </div>
    </div>
  </section>
  )
}

export default Welcome;
