import React from 'react';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

const Contact = () => {
  return (
    <section className="ftco-intro">
    <div className="container-wrap">
      <div className="wrap d-md-flex">
        <div className="info">
          <div className="row no-gutters">
            <div className="col-md-4 d-flex">
              <div className="icon"><span><LocalPhoneIcon/></span></div>
              <div className="text">
                <h3>+94 74 130 5511</h3>
                <p>Contact</p>
              </div>
            </div>
            <div className="col-md-4 d-flex">
              <div className="icon"><span><MyLocationIcon/></span></div>
              <div className="text">
                <h3>228, Balakaduwa, </h3>
                <p>Alawathugoda. </p>
                <p>Kandy, Sri Lanaka </p>
              </div>
            </div>
            <div className="col-md-4 d-flex">
              <div className="icon"><span><AccessTimeIcon/></span></div>
              <div className="text">
                <h3>Open Monday-Sunday</h3>
                <p>8:00am - 8:00pm</p>
              </div>
            </div>

          </div>
        </div>

        <div className="social d-md-flex pl-md-5 p-4 align-items-center">
          <ul className="social-icon">
            <li className=""><a href="#"><span><TwitterIcon/></span></a></li>
            <li className=""><a href="#"><span><FacebookIcon/></span></a></li>
            <li className=""><a href="#"><span><InstagramIcon/></span></a></li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Contact;
