import Contact from './components/Contact/contact';
import Contactus from './components/ContactUs/contactus';
import Footer from './components/Footer/footer';
import Hero from './components/HeroSection/hero';
import Navbar from './components/NavBar/navbar';
import Products from './components/Products/products';
import Section2 from './components/Section2/section2';
import Welcome from './components/Welcome/welcome';

function App() {
  return (
    <div className="App">
      <Navbar/>
      <Hero/>
      <Contact/>
      <Welcome/>
      <Section2/>
      <Products/>
      <Contactus/>
      <Footer/>
    </div>
  );
}

export default App;
