import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
// import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported
import logo from './img/logo.png';
import shop1 from './img/shop1.jpg';
import shop2 from './img/shop2.jpg';
import './hero.css'; // If you have custom styles for the carousel

const Hero = () => {
  return (
    <Carousel
      prevLabel=""
      nextLabel=""
      prevIcon={<span className="carousel-control-prev-icon" aria-hidden="true"></span>}
      nextIcon={<span className="carousel-control-next-icon" aria-hidden="true"></span>}
    >
      <Carousel.Item interval={2000}>
          <div className="carousel-item-background" id="item0">
            <Carousel.Caption className="carousel-caption-center">
              <span className="subheading">Amethyst</span>
              <h1 className="mb-4">SWEETS</h1>
              <div className="col-md-12">
                <img src={logo} className="img-fluid" alt="" id='logo1'/>
              </div>
            </Carousel.Caption>
        </div>
      </Carousel.Item>

      <Carousel.Item interval={2000}>
        <div className="carousel-item-background" id="item1">
          <Carousel.Caption className="carousel-caption-center">
            <span className="subheading">Welcome to Amethyst</span>
            <h1 className="mb-4">Get surprise for your tongue.</h1>
          </Carousel.Caption>
        </div>
      </Carousel.Item>

      <Carousel.Item interval={2000}>
        <div className="carousel-item-background" id="item2">
          <Carousel.Caption className="carousel-caption-center">
            <span className="subheading">Amethyst</span>
            <h1 className="mb-4">Sweets</h1>
            <p id='text1' className="mb-4 mb-md-5">ඔබට අවශ්‍ය රසකැවිලි තොග හා <br/> සිල්ලර මිලට අපගෙන් ලබගත හැක.</p>
            <div>
              <img src={shop1} className="img-fluid" alt=""/>
            </div>
          </Carousel.Caption>
        </div>
      </Carousel.Item>

      <Carousel.Item interval={2000}>
        <div className="carousel-item-background" id="item3">
          <Carousel.Caption className="carousel-caption-center">
            <span className="subheading">Amethyst</span>
            <h1 className="mb-4">Sweets</h1>
            <p id='text2' className="mb-4 mb-md-5">ඔබට අවශ්‍ය රසකැවිලි තොග හා <br/> සිල්ලර මිලට අපගෙන් ලබගත හැක.</p>
            <div>
              <img src={shop2} className="img-fluid" alt=""/>
            </div>
          </Carousel.Caption>
        </div>
      </Carousel.Item>

      {/* Add more Carousel.Items here */}
    </Carousel>
  );
}

export default Hero;
