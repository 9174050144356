import React from 'react';
import './footer.css';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CallIcon from '@mui/icons-material/Call';

import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';

const Footer = () => {

    const currentYear = new Date().getFullYear();

  return (
    <footer className="ftco-footer ftco-section img" id='about'>
    	<div className="overlay"></div>
      <div className="container">
        <div className="row mb-5">
          <div className="col-lg-3 col-md-6 mb-5 mb-md-5">
            <div className="ftco-footer-widget mb-4">
              <h2 className="ftco-heading-2">About Us</h2>
              <p> <span className="subheading">Amethyst</span> we are the large scale sweets manufature and distributers in Sri Lanka.</p>
              <p>Our mission is to deliver high quality and safe products to the customers.</p>
            
              <ul className="ftco-footer-social list-unstyled float-md-left float-lft mt-5">
                <li><a href="https://www.facebook.com/profile.php?id=61563171405646&mibextid=ZbWKwL"><span className='socialIcons'><FacebookIcon/></span></a></li>
                <li><a href="https://wa.me/94741305511"><span className='socialIcons'><WhatsAppIcon/></span></a></li>
              </ul>
            </div>
          </div>
         
          
          <div className="col-lg-3 col-md-6 mb-5 mb-md-5">
            <div className="ftco-footer-widget mb-4">
            	<h2 className="ftco-heading-2">Contact us</h2>
            	<div className="block-23 mb-3">
	              <ul>
	                <li><span className='icon'><LocationOnIcon/></span><span className="text">228, Balakaduwa, Alawathugoda.</span></li>
                  <li><span className="icon"><LocationOnIcon/></span><span className="text">228, Kandy, Sri Lanka</span></li>
	                <li><a href="#"><span className="icon"><CallIcon/></span><span className="text">+94 74 130 5511</span></a></li>
	                <li><a href="#"><span className="icon"><EmailIcon/></span><span className="text">Amethystsmart@gmail.com</span></a></li>
	              </ul>
	            </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-center">

           {/* <p> Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
        <p> Copyright &copy;{currentYear} by Amethyst Smart. All rights reserved.</p>
            {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0.</p> */}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;
