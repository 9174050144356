import React from 'react';
import item_2 from '../../img/item_2.png';
import item_3 from '../../img/item_3.png';
import Carousel from 'react-bootstrap/Carousel';
import './section2.css';

const Section2 = () => {
  return (


    <Carousel
      prevLabel=""
      nextLabel=""
      prevIcon={<span className="carousel-control-prev-icon" aria-hidden="true"></span>}
      nextIcon={<span className="carousel-control-next-icon" aria-hidden="true"></span>}
    >

      <Carousel.Item interval={2000}>
        <div className="carousel-item-background" id="item2">
          <Carousel.Caption className="carousel-caption-center">
            <span className="subheading">Amethyst</span>
            <h1 className="mb-4">Jaggery Dodol.</h1>
            <p id='text3' className="mb-4 mb-md-5">Kithul Jaggery mixed dodol with cashew nuts.</p>
            <div className="col-md-12">
              <img src={item_2} className="img-fluid" alt=""/>
            </div>
          </Carousel.Caption>
        </div>
      </Carousel.Item>

      <Carousel.Item interval={2000}>
        <div className="carousel-item-background" id="item2">
          <Carousel.Caption className="carousel-caption-center">
            <span className="subheading">Amethyst</span>
            <h1 className="mb-4">Kithul Jaggery.</h1>
            <div className="col-md-12">
              <img src={item_3} className="img-fluid" alt=""/>
            </div>
          </Carousel.Caption>
        </div>
      </Carousel.Item>
      

      {/* Add more Carousel.Items here */}
    </Carousel>
  )
}

export default Section2;
