import React from 'react';
import Swal from 'sweetalert2';
import './contactus.css';

const Contactus = () => {

	const onSubmit = async (event) => {
		event.preventDefault();
		const formData = new FormData(event.target);
	
		formData.append("access_key", "5d808102-11cd-4a9e-ab29-1516d6fad0b2");
	
		const object = Object.fromEntries(formData);
		const json = JSON.stringify(object);
	
		const res = await fetch("https://api.web3forms.com/submit", {
		  method: "POST",
		  headers: {
			"Content-Type": "application/json",
			Accept: "application/json"
		  },
		  body: json
		}).then((res) => res.json());
	
		if (res.success) {
			Swal.fire({
				title: "Success!",
				text: "Message sent successfully!",
				icon: "success"
			  });
		}
	  };

  return (
    <section className="ftco-appointment">
			<div className="overlay"></div>
    	<div className="container-wrap">
    		<div className="row no-gutters d-md-flex align-items-center">
    			<div className="col-md-6 d-flex align-self-stretch">
    				{/* <div id='map'></div> */}
    			</div>
	    		<div className="col-md-6 appointment">
	    			<h3 className="mb-3">Contact Us</h3>
	    			<form onSubmit={onSubmit} className="appointment-form">
	    				<div className="d-md-flex">
		    				<div className="form-group">
		    					<input type="text" className="form-control" placeholder="Name" name='name' required/>
		    				</div>
	    				</div>
	    				<div className="d-me-flex">
	    					<div className="form-group">
		    					<input type="number" className="form-control" placeholder="Mobile No" name='mobile' required/>
		    				</div>
	    				</div>
	    				<div className="form-group">
	              <textarea name="message" cols="30" rows="3" className="form-control" placeholder="Message" required></textarea>
	            </div>
	            <div className="form-group">
	              <input type="submit" value="Send" className="btn btn-primary py-3 px-4"/>
	            </div>
	    			</form>
	    		</div>    			
    		</div>
    	</div>
    </section>

  )
}

export default Contactus;
