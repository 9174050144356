import React from 'react';
import './products.css';

const Products = () => {
  return (
    <section className="products" id='products'>
      <div className="container">
        <div className="row justify-content-center mb-5 pb-3">
          <div className="col-md-7 heading-section text-center">
            <h2 className="mb-4">Our Products</h2>
            
          </div>
        </div>
      </div>
      <div className="container-wrap">
  <div className="row no-gutters justify-content-center">
    <div className="col-lg-6 d-flex" id="sec1">
      <div className="services-wrap d-flex">
        <a href="#" className="img" id="itemimg1"></a>
        <div className="text p-4">
          <h3>Amethyst Jaggery Dodol</h3>
          <h3>ඇමෙතිස්ට් කිතුල් හකුරු දොදොල්</h3>
          <p>Kithul Jaggery mixed cashew nuts dodol.</p>
          <p>කිතුල් හකුරු මිශ්‍ර කජු දොදොල්.</p>
          <p className="price">500g <span>Rs.800/=</span> </p>
        </div>
      </div>
    </div>
    <div className="col-lg-6 d-flex">
      <div className="services-wrap d-flex" id="sec2">
        <a href="#" className="img" id="itemimg2"></a>
        <div className="text p-4">
          <h3>Amethyst Kithul Jaggery</h3>
          <h3>ඇමෙතිස්ට් කිතුල් හකුරු</h3>
          <p className="price">500g <span>Rs.500/=</span> </p>
        </div>
      </div>
    </div>
  </div>
</div>

</section>
  )
}

export default Products;
